$(document).ready(function() {
    if (exists('#cart-container')) {
        cartPage();
    }
    else if (exists('#catalog-container')) {
        catalogPage();
    }
    else if (exists('#checkout-container')) {
        checkoutPage();
    }
    else if (exists('#profile-container')) {
        profilePage();

        if (exists('.table-orders')) {
            profileOrdersPage();
        }
        if (exists('.table-subscriptions')) {
            subscriptionsPage();
        }
    }
    else if (exists('.login-subscribe-container')) {
        loginSubscribePage();
    }
    else if (exists('#subscription-activate-form, .subscriptions-container')) {
        subscriptionsPage();
    }

    if (exists('.catalog-product, #product-container')) {
        productComponent();
    }
    if (exists('.main-menu, .navigation-menu')) {
        menuComponent();
    }
    if (exists('#subscribe-newsletter')) {
        newsletterComponent();
    }
});