function contactFormRecaptchaCallback() {
  $("#contact-form").validate({
    rules: {
      firstname: "required",
      email: "required",
      subject: "required",
      message: "required"
    },
    errorPlacement: function (error, element) {
      error.appendTo("");
    }
  });

  if ($("#privacy").is(":checked") == true) {

    if ($("#contact-form").valid() == true) {
      $.ajax({
        type: $("#contact-form").attr('method'),
        url: $("#contact-form").attr('action'),
        data: $("#contact-form").serialize(),
        beforeSend: function (data) {
        },
        success: function (data) {
          if (data.success) {
            showMessageBox('success', msg['MSG_MESSAGE_SENT']);
            $("#contact-form").find(':input').prop('disabled', true);
          } else {
            showMessageBox('danger', data.errors.message);
          }
        },
        failed: function (data) {
          showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
        },
        complete: function (data) {
          grecaptcha.reset();
        }
      })

    } else {
      grecaptcha.reset();
    }

  } else {
    grecaptcha.reset();
    showMessageBox('danger', msg['MSG_PRIVACY_POLICY']);
  }

  $("#contact-form").find(':input[type = "submit"]').prop('disabled', false);
  //$("#contact-form-receipent").find(':input[type = "submit"]').prop('disabled', false);

}

function contactFormPartnerRecaptchaCallback() {
  $("#contact-form-partner").validate({
    rules: {
      name: "required",
      email: "required",
      subject: "required",
      message: "required",
      company: "required"
    },
    errorPlacement: function (error, element) {
      error.appendTo("");
    }
  });

  if ($("#privacy-partner").is(":checked") == true) {

    if ($("#contact-form-partner").valid() == true) {
      $.ajax({
        type: $("#contact-form-partner").attr('method'),
        url: $("#contact-form-partner").attr('action'),
        data: $("#contact-form-partner").serialize(),
        beforeSend: function (data) {
        },
        success: function (data) {
          if (data.success) {
            showMessageBox('success', msg['MSG_MESSAGE_SENT']);
            $("#contact-form-partner").find(':input').prop('disabled', true);
          } else {
            showMessageBox('danger', data.errors.message);
          }
        },
        failed: function (data) {
          showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
        },
        complete: function (data) {
          grecaptcha.reset();
        }
      })

    } else {
      grecaptcha.reset();
    }

  } else {
    grecaptcha.reset();
    showMessageBox('danger', msg['MSG_PRIVACY_POLICY']);
  }

  $("#contact-form-partner").find(':input[type = "submit"]').prop('disabled', false);
  //$("#contact-form-receipent").find(':input[type = "submit"]').prop('disabled', false);

}
