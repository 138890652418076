setInterval(function() {
    const salesList = $('.sales-item').toArray();

    for (let currentSales of salesList) {
        const endtime = $(currentSales).data( "endtime" );

        const countDownDate = new Date(endtime).getTime();
        const distance = countDownDate - Date.now();

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        $(currentSales).find('.sales-item-countdown-date').text(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    }
}, 1000);
