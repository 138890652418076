'use strict';

var dropCookie = true;

fillCountryPrefix();

function createCookie(name, value, days) {
    var expires;
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = '; expires=' + date.toGMTString();
    } else {
        expires = '';
    }
    if (dropCookie) {
        document.cookie = name + '=' + value + expires + '; path=/';
    }
}

function checkCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

function eraseCookie(name) {
    createCookie(name, '', -1);
}

function getQueryParams(url) {
    let queryParams = {};
    let [_, params] = url.split('?');

    if (params) {
        for (let param of params.split('&')) {
            let [key, value] = param.split('=');
            queryParams[key] = value || '';
        }
    }

    return queryParams;
}

function getQueryParam(variable) {
    return getQueryParams(window.location.href)[variable] || '';
}

function exists(selector) {
    try {
        return $('html').find(selector).length > 0;
    }
    catch {
        return false;
    }
}

function not(condition) {
    return !condition || condition == 'false' || ((condition instanceof String || typeof condition === 'string') && condition != 'true');
}

function yes(condition) {
    return !not(condition);
}

function loadProvinces(countryId) {
    return new Promise((resolve) => {
        $.ajax({
            method: 'GET',
            url: `/restful/countries/provinces?countryId=${countryId}`,
            success: function (data) {
                resolve(data.results);
            },
            error: function () {
                resolve([]);
            }
        });
    });
}

function loadQtyInputs() {
    const uninitializedInputs = function () {
        return not($(this).data('init'));
    }

    $('.i-number').filter(uninitializedInputs).each(function () {
        const $this = $(this);
        const max = $this.data('max') >> 0;
        const min = $this.data('min') >> 0;
        const initValue = $this.val();
        let restoreColor = () => { };

        if (initValue > max || initValue < min) {
            $this.css('color', 'red');
            restoreColor = () => $this.css('color', false);
        }

        $('<a class="i-number-btn i-number-minus" href="#" role="button" tabindex="-1"><img src="/skins/current-skin/images/icons/qty-minus.svg" loading="lazy" width="14" height="14"/></a>')
            .insertBefore($this)
            .on('click', function (event) {
                event.preventDefault();
                const value = getCurrentValue($this);
                checkValue(value - 1);
            });

        $('<a class="i-number-btn i-number-plus" href="#" role="button" tabindex="-1"><img src="/skins/current-skin/images/icons/qty-plus.svg" loading="lazy" width="14" height="14"/></a>')
            .insertAfter($this)
            .on('click', function (event) {
                event.preventDefault();
                const value = getCurrentValue($this);
                checkValue(value + 1);
            });

        $this.on('input keydown', function (event) {
            const value = getCurrentValue($this);
            const key = (event.key || '').toUpperCase();

            if (key == 'ENTER') {
                event.preventDefault();
                $this.closest('form').trigger('submit');
            }
            else if (key == 'ARROWUP') {
                checkValue(value + 1);
            }
            else if (key == 'ARROWDOWN') {
                checkValue(value - 1);
            }
            else {
                checkValue(value);
            }
        });

        $this.data('init', true);

        function getCurrentValue($this) {
            return ($this.val() + '').replace(/[^0-9]/g, '') >> 0;
        }

        function checkValue(value) {
            if (value > max) {
                $this.val(max);
            }
            else if (value < min) {
                $this.val(min);
            }
            else {
                $this.val(value);
            }
            restoreColor();
            checkInputEnabled();
        }

        function checkInputEnabled() {
            const $form = $this.closest('form');
            const totalQuantity = $form.find('.i-number').toArray()
                .some(input => $(input).val() != $(input).data('qtyorig'));

            if (totalQuantity > 0) {
                $form.find('[type="submit"]').prop('disabled', false);
            }
            else {
                $form.find('[type="submit"]').prop('disabled', true);
            }
        }
    });
}

function fillCountryPrefix() {

    if ($('.fill-country-prefix')) {

        for (i = 0; i < prefix_list.length; i++) {
            if (prefix_list[i].dial_code == '+39') {
                $('.fill-country-prefix').append("<option code='" + prefix_list[i].code + "' value= " + prefix_list[i].dial_code + " selected=\"selected\">" + prefix_list[i].name + ": " + prefix_list[i].dial_code + "</option>");
            } else {
                $('.fill-country-prefix').append("<option code='" + prefix_list[i].code + "' value= " + prefix_list[i].dial_code + ">" + prefix_list[i].name + ": " + prefix_list[i].dial_code + "</option>");
            }

        }

    }
}

function requireInput(selector, value = 'required') {
    if (exists(selector)) {
        const input = $(selector);
        const label = input.closest('.label-input').find('label');
        if (!label.html().includes(' *')) {
            input.prop('required', value);
            label.html(label.html() + ' *');
        }
    }
}
function dontRequireInput(selector) {
    if (exists(selector)) {
        const input = $(selector);
        const label = input.closest('.label-input').find('label');
        input.prop('required', false);
        label.html(label.html().replace(' *', ''));
    }
}
function checkRequiredFields() {
    const code = $("#country").find('option:selected').data('eu');
    if (code == 'EU') {
        requireInput('[name*=cfpiva]');
        dontRequireInput('[name*=pec]');
        dontRequireInput('[name*=sdi]');
        dontRequireInput('[name*=prov]');
    } else if (code == 'IT') {
        requireInput('[name*=cfpiva]');
        requireInput('[name*=pec]', false);
        requireInput('[name*=sdi]', false);
        requireInput('[name*=prov]');
    } else if (code != undefined) {
        dontRequireInput('[name*=cfpiva]');
        dontRequireInput('[name*=pec]');
        dontRequireInput('[name*=sdi]');
        dontRequireInput('[name*=prov]');
    }
}


$('.tab').on('click', function () {
    var tab = $(this).data('tab');
    $('.tab').removeClass('active');
    $('.tab-container').removeClass('active');

    $(this).addClass('active');
    $("#" + tab).addClass('active');
});

$(document).ready(function () {
    $('.fill-country-prefix').on('change', function () {
        $('#image-prefix').attr('src', baseUrl + $('option:selected', this).attr('code').toLowerCase() + ".svg")
    });

    const currentCurrency = checkCookie('currency');
    $('[data-currency]').on('click', function (event) {
        event.preventDefault();
        const currency = $(this).data('currency');

        if (currency != currentCurrency) {
            createCookie('currency', currency);
            window.location.reload();
        }
    });

    if (exists('[name*="country"]')) {
        $('[name*="country"]').on('change', checkRequiredFields);
        checkRequiredFields();

        $('[name*="country"]').on('change', updateProvinces);
        updateProvinces();

        function updateProvinces() {
            $('[name*="prov"]').each(function () {
                const $this = $(this);
                const country = $this.closest('form').find('[name*="country"]');

                if (country.length > 0) {
                    loadProvinces(country.val()).then(function (provinces) {
                        if (provinces.length > 0) {
                            const id = $this.attr('id');
                            const name = $this.attr('name');
                            const type = $this.attr('type');
                            const value = $this.val();
                            const placeholder = $this.attr('placeholder');
                            const disabled = $this.is('[disabled]') ? 'disabled' : '';
                            let options = [];
                            let hasSelectedValue = false;

                            for (let i = 0; i < provinces.length; i++) {
                                const province = provinces[i];
                                const selected = (province.code == value) ? 'selected' : '';
                                options.push(`<option value="${province.code}" ${selected}>${province.name} (${province.code})</option>`);
                                hasSelectedValue = hasSelectedValue || (province.code == value);
                            }
                            if (!hasSelectedValue) {
                                options[0].replace('  ', ' selected');
                            }
                            $this.replaceWith(`
                                <select id="${id}" name="${name}" ${disabled} data-type="${type}" data-placeholder="${placeholder}" data-value="${value}" required aria-required="true">
                                    ${options.join('\n')}
                                </select>
                            `);
                        }
                        else if ($this.is('select')) {
                            const id = $this.attr('id');
                            const name = $this.attr('name');
                            const type = $this.data('type');
                            const value = $this.data('value');
                            const placeholder = $this.data('placeholder');
                            const disabled = $this.is('[disabled]') ? 'disabled' : '';
                            $this.replaceWith(`<input id="${id}" type="${type}" name="${name}" ${disabled} value="${value}" placeholder="${placeholder}" required aria-required="true"/>`);
                        }
                    });
                }
            });
        }
    }

    setupIntlTelInput('cel');
    setupIntlTelInput('tel');
    function setupIntlTelInput(name) {
        const telInput = $(`input[name*="${name}"]`).not('[name*="prefix"]').not('[type="hidden"]');
        telInput.each(function (idx) {
            const tel = $(telInput[idx]);
            const telPrefix = tel.closest('form').find(`[name*="${name}_prefix"]`);
            tel.val(telPrefix.val() + tel.val());

            const iti = intlTelInput(telInput[idx], {
                separateDialCode: true,
                utilsScript: '/skins/current-skin/js/vendor/utils.js'
            });

            tel.on('countrychange', function () {
                const prefix = '+' + iti.getSelectedCountryData().dialCode;
                telPrefix.val(prefix);
            });
        });
    }

    $('.faq .toggle').on('click', function () {
        const faq = $(this).parent();
        $('.faq').not(faq)
            .removeClass('open')
            .find('.answer').slideUp();
        faq
            .toggleClass('open')
            .find('.answer').slideToggle();
    });

    $('.home-filter').on('click', function () {
        $('#loader').addClass('lds-dual-ring');
        $('.home-filter').removeClass('blue');
        $('.related-slider').addClass('hide');
        var target = $(this).attr('data-target');
        $(this).addClass('blue');
        $('.' + target).removeClass('hide');
        setTimeout(function () {
            $('#loader').removeClass('lds-dual-ring');
        }, 2000);
    });

    jQuery.validator.addMethod(
        "regex",
        function (value, element, regexp) {
            const re = new RegExp(regexp);
            return this.optional(element) || re.test(value);
        },
        "Please check your input."
    );

    jQuery.validator.messages.required = msg['ERR_MISSINGFIELDS'];

    // Rewixsync
    $('#install-shop').validate({
        rules: {
            shop: {
                required: true,
                regex: /^(?!:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm // match URL that does NOT start with http(s)://
            }
        },
        messages: {
            shop: {
                required: msg['ERR_MISSINGFIELDS'],
                regex: msg['ERR_REWIXSYNC_URL']
            }
        },
        errorPlacement: function (error, element) {
            $(error)
                .css({
                    'position': 'relative',
                    'top': '-16px',
                    'width': 'max-content'
                })
                .insertAfter(element);
        }
    });

    $('[href^="#"]').on('click', function (event) {
        const href = $(this).attr('href');
        try {
            const element = $(href);
            if (element.length > 0) {
                event.preventDefault();
                const header = document.querySelector('header');
                const navigation = document.querySelector('.navigation');
                const lettersDiv = document.querySelector('.letters-div');
                let headerHeight = lettersDiv.offsetHeight;
                if (window.getComputedStyle(header).position == 'sticky') {
                    headerHeight += header.offsetHeight;
                }
                else {
                    headerHeight += navigation.offsetHeight;
                }
                const scrollPosition = element.offset().top;
                window.scrollTo({top: scrollPosition - headerHeight});
                history.pushState(null, '', href);
            }
        }
        catch {}
    });
});

function GTMCallback(callback) {
    return (containerId) => {
        if ((!containerId || containerId.startsWith("GTM-")) && typeof callback === "function") {
            callback();
        }
    }
}

function pushGTMEventWithCallback(timeout, options) {
    const dataLayer = window.dataLayer ?? [];

    let callbackExecuted = false;
    dataLayer.push({
        ...options,
        ...(options.eventCallback
            ? {
                eventCallback: GTMCallback(() => {
                    if (!callbackExecuted) {
                        callbackExecuted = true;
                        options.eventCallback();
                    }
                })
            }
            : {}
        )
    });

    if (options.eventCallback) {
        setTimeout(() => {
            if (!callbackExecuted) {
                callbackExecuted = true;
                options.eventCallback();
            }
        }, timeout);
    }
}