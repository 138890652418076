function checkoutPage() {
    'use strict';

    function submitAddressEditForm(form, shippingForm=false) {
        var href = form.data('nextstep');
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            success: function(data) {
                if (data.success && data.data == 'VAT_CHANGED') {
                    href = '//' + location.host + location.pathname + '?warning=msg.order.vat.changed';
                }

                if (shippingForm && window.dataLayer) {
                    dataLayer.push({ ecommerce: null });
                    pushGTMEventWithCallback(500, {
                        event: 'add_shipping_info',
                        ecommerce: window.ecommerce,
                        eventCallback: GTMCallback(function() {
                            window.location.href = href;
                        })
                    });
                }
                else {
                    window.location.href = href;
                }
            },
            error: function(xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    }

    $('#dispatch-address-form').validate({
        submitHandler: function(form, event) {
          event.preventDefault();
          submitAddressEditForm($(form), true);
        }
    });
        
    $('#billing-address-form').validate({
        submitHandler: function(form, event) {
          event.preventDefault();
          if(!$('#clausola1').is(':checked')) {
            showMessageBox('danger', msg['ERR_PRIVACY']);
          } else {
              if ($("select[name*=country]").val() == '108') {
                if ($("#pec").val() == '' && $("#sdi").val() == '' ) {
                    showMessageBox('danger', 'Compila almeno un campo tra PEC e SDI');
                }
                else {
                    submitAddressEditForm($(form));
                }
              }
              else {
                submitAddressEditForm($(form));
              }
          }
        }
    });


    $('[id*=setefi-form]').on('submit', function(e) {
        e.preventDefault();
        var action = $(this).attr('action');
        $.ajax({
            type : 'POST',
            url : action,
            async: false,
            dataType : "json",
            success : function(data) {
                if (data.success) {
                    if (window.dataLayer) {
                        dataLayer.push({ ecommerce: null });
                        pushGTMEventWithCallback(500, {
                            event: 'add_shipping_info',
                            ecommerce: window.ecommerce('Setefi'),
                            eventCallback: GTMCallback(function() {
                                window.location.href = data.message;
                            })
                        });
                    }
                    else {
                        window.location.href = data.message;
                    }
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function() {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('#cod-btn').on('click', function(event) {
        const btn = this;

        if (window.dataLayer) {
            event.preventDefault();
            event.stopPropagation();

            dataLayer.push({ ecommerce: null });
            pushGTMEventWithCallback(500, {
                event: 'add_payment_info',
                ecommerce: window.ecommerce('COD'),
                eventCallback: GTMCallback(function() {
                    const form = btn.closest('form');
                    if (form.requestSubmit) {
                        form.requestSubmit();
                    }
                    else {
                        form.submit();
                    }
                })
            });
        }
    });

    $('#booking-btn').on('click', function(event) {
        const btn = this;

        if (window.dataLayer) {
            event.preventDefault();
            event.stopPropagation();

            dataLayer.push({ ecommerce: null });
            pushGTMEventWithCallback(500, {
                event: 'add_payment_info',
                ecommerce: window.ecommerce('Booking'),
                eventCallback: GTMCallback(function() {
                    const form = btn.closest('form');
                    if (form.requestSubmit) {
                        form.requestSubmit();
                    }
                    else {
                        form.submit();
                    }
                })
            });
        }
    });
}