function catalogPage() {
    'use strict';

    onCatalogLoad();
    $(window).on('popstate', reloadCatalog);
    $(window).on('catalog:reload', reloadCatalog);

    function onCatalogLoad() {
        setupLayoutOptions();
        setupSearchResults();
        setupFilters();
        setupPagination();
        loadQtyInputs();

        $(".nano").nanoScroller();
    }

    function reloadCatalog() {
        $.ajax({
            url: window.location.href,
            type: "GET",
            dataType: "html",
            beforeSend: function () {
                $('#catalog-container').addClass('catalog-loading');
            },
            success: function (data) {
                const response = $('<div>').append($(data));
                replaceCatalogElements(response, ['#catalog-wrapper']);
                productComponent();
                onCatalogLoad();
            },
            error: function () {
                window.location.reload();
            },
            complete: function () {
                $('#catalog-container').removeClass('catalog-loading');
            }
        });
    }

    function replaceCatalogElements(data, elements) {
        const $data = $(data);

        for (let element of elements) {
            $(element).replaceWith($data.find(element));
        }
    }

    function setupFilters() {
        $('#filters-dropdown .filter-name').on('click', function () {
            const $filter = $(this).closest('.filter-tag');
            const tagId = $filter.data('tagid')
            $(this).trigger('filter:open', tagId);
        });

        $('#filters-dropdown .apply-filter').on('click', function () { applyFilters(this); });
        $('#filters-dropdown .apply-size-filter').on('click', function () { applyFilters(this, true); });

        function applyFilters(elem, size = false) {
            let tags = {};
            const dropdown = $(elem).closest('.filter-dropdown');
            const baseUrl = dropdown.find('ul').data('baseurl');
            const tagId = size ? 'size' : `tag_${dropdown.find('ul').data("tagid")}`;

            tags[tagId] = [];
            dropdown.find('.active').each(function () {
                const liTag = $(this);
                tags[tagId].push(liTag.data('tagvalue'));
            });

            let url;
            if (Object.keys(tags).length > 0) {
                const joinWith = baseUrl.includes('?') ? '&' : '?';
                url = `${baseUrl}${joinWith}${jQuery.param(tags, true)}`;
            }
            else {
                url = baseUrl;
            }

            pushAndReload(url);
        }

        $('.current-filter, .sort-dropdown a').on('click', function (event) {
            event.preventDefault();
            const href = $(this).attr('href');
            pushAndReload(href);
        });

        $('#filters-dropdown li').on('click', function (event) {
            const liTag = $(this);
            const count = $('#filters-dropdown li.active').length;
            if (count >= 9 && not(liTag.is('.active'))) {
                showMessageBox('danger', general_toomanyfilters);
                $('#filters-dropdown')[0].focus();
            }
            else {
                liTag.toggleClass('active');
            }
            return false;
        });

        $('#filters-dropdown').on('filter:open', function (event, tagId) {
            const filterTags = $(this).find('.filter-tag');
            closeFilters(filterTags.not(`[data-tagid="${tagId}"]`));
            openFilter(filterTags.filter(`[data-tagid="${tagId}"]`));
        });

        function closeFilters(filters) {
            $(filters).each(function () {
                const $filter = $(this);
                $filter.removeClass('open');
                $filter.find('.filter-dropdown').slideUp();
            });
        }

        function openFilter(filter) {
            const $filter = $(filter);
            $filter.toggleClass('open')
                .find('.filter-dropdown').slideToggle();
            window.dispatchEvent(new Event('resize')); //Hack to make nanoscroller work
        }
    }

    function setupPagination() {
        $('.pagination a').on('click', function (event) {
            event.preventDefault();
            const href = $(this).attr('href');
            pushAndReload(href);
        });
    }

    function setupSearchResults() {
        const search = unescape(getQueryParam('search')).replace('+', ' ');
        if (search && search != 'undefined') {
            $('#search-keyword').html(search);
            $('.no-search-found').removeClass('hide');
        }
    }

    function setupLayoutOptions() {
        $('#layout-options input').on('change', function () {
            const layout = $(this).val();
            
            if (checkCookie('catalogLayout') != layout) {
                createCookie('catalogLayout', layout);
                $(this).trigger('catalog:reload');
            }
        });
    }

    function pushAndReload(url) {
        window.history.pushState('', '', url);
        $('#catalog-container').trigger('catalog:reload');
    }
}