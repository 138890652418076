function menuComponent() {

    $('.mobile-menu__language').on('click', function () {
        $(this).siblings('ul').slideToggle();
    });

    $( "#mainsearch-mobile" ).focusin(function() {
        $('#header-link').slideToggle();
    });

    $( "#mainsearch-mobile" ).focusout(function() {
        $('#header-link').slideToggle();
    });

    $('.close-top-banner').on('click', function () {
        $('.top-banner').hide();
        $('.navigation').addClass('top-banner-closed');
        $('.nav-toggle').addClass('top-banner-closed');
    });

    $('#search-trigger').on('click', function (e) {
        e.preventDefault();
        $('#search-modal').addClass('search-open');
    });

    $('.close-search-modal').on('click', function (e) {
        e.preventDefault();
        $('#search-modal').removeClass('search-open');
    });

    //Stickt button

    var btn = $('#button');

    $(window).scroll(function() {
    if ($(window).scrollTop() > 300) {
        btn.addClass('show');
    } else {
        btn.removeClass('show');
    }
    });

    btn.on('click', function(e) {
    e.preventDefault();
    $('html, body').animate({scrollTop:0}, '300');
    });

    // sticky menu
    setStickyMenu();
    $(window).on('scroll', setStickyMenu);
    function setStickyMenu() {
        if ($(this).scrollTop() > 120) {
            $('#cart-icon').addClass("sticky");
            $('.navigation').addClass("sticky");
        }
        else {
            $('#cart-icon').removeClass("sticky");
            $('.navigation').removeClass("sticky");
        }
    }

    $('.letters-div').addClass("sticky-letters");


    // mobile menu
    $('.nav-toggle').click(function() {
        this.classList.toggle("active");
        // If sidebar is visible:
        if ($('body').hasClass('show-nav')) {
            // Hide sidebar
            $('body').removeClass('show-nav');
        } else { // If sidebar is hidden:
            $('body').addClass('show-nav');
            // Display sidebar
        }
    });

    function getUrlVars(url) {
        var vars = {};
        if (url == undefined) {
            var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
                vars[key] = value;
            });
        } else {
            var parts = url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
                vars[key] = value;
            }); 
        }
        return vars;
    }

    var params= getUrlVars();

    if (params.letter != undefined) {
            var element = document.getElementById('letters-' + params.letter)
            var headerOffset = 121 + 84;
            if (Foundation.MediaQuery.is('large')) {
                headerOffset = 60 + 84;
            }
            var elementPosition = element.getBoundingClientRect().top;
            var offsetPosition = elementPosition - headerOffset;;

            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth"
            });  
    }

    $('#cart-icon').on('click', function (event) {
        const target = $(event.target);
        const targetIsPopup = target.is('.popup') || exists(target.closest('.popup'));
        if (not(targetIsPopup)) {
            const icon = $('#cart-icon');
            icon.toggleClass('open');

            const popup = $('#cart-icon .popup');
            if (icon.is('.open') && not(popup.is(':visible'))) {
                window.location.href = $('#cart-icon .link-to-cart').attr('href');
            }
        }
    });
    
    $('body').on('click', function (event) {
        const target = $(event.target);

        if ($('#cart-icon').is('.open') && not(target.is('#cart-icon') || exists(target.closest('#cart-icon')))) {
            $('#cart-icon').removeClass('open');
        }
    });

    setupCartPopup();
}

function setupCartPopup() {

    $('#cart-icon .popup .product-remove').on('click', function (event) {
        event.preventDefault();
        const $form = $(this).closest('form');

        $.ajax({
            method: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            success: function (data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_REMOVEDFROMCART'])
                    reloadCartPopup();
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function () {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('#cart-icon .popup .close-popup').on('click', function () {
        $('#cart-icon').removeClass('open');
    });

    $('#product-container .sizes input.size').on('click', function (event) {
      var input = $(this);
      // set 0 to all the other values
      input.closest('form').find('input[type=hidden].size-qty-input').each(function () {
        $(this).val($(this).data('qtyorig'));
      });
      // set for the selected input size the value 1
      var target = $(input.data('target'));
      target.val(parseInt(target.data('qtyorig')) + 1);
    });
}

function reloadCartPopup() {
    $.ajax({
        method: 'GET',
        url: window.location.href,
        dataType: 'html',
        success: function (data) {
            const response = $('<div>').append(data);
            const count = response.find('#cart-icon .count').html();
            const popup = response.find('#cart-icon .popup').html();
            $('#cart-icon .count').html(count);
            $('#cart-icon .popup').html(popup);
            setupCartPopup();

            $('#cart-icon').addClass('open');
        }
    });
}