$(document).ready(function () {

    $('#support-trigger').on('click', function () {
        $(this).fadeOut();
        $('#support-popup').fadeIn();
    });

    $('#support-close').on('click', function () {
        $('#support-popup').fadeOut();
        $('#support-trigger').fadeIn();
    });

});