'use strict';

$(document).ready(function() {
  $('form#easydrop-form').on('submit', function(event) {
    event.preventDefault();
    var $form = $('<form>').attr({method: 'GET'});

    var action = $(this).attr('action') + '.' + $('#filetype').val();
    $form.attr('action', action);

    $form.append($('input[name=acceptedlocales]')[0].outerHTML);
    $(this).find('select').each(function() {
      var $option = $(this);
      var name = $option.attr('name');
      if ($option.val().length > 0) {
        $form.append($('<input>').attr({type: 'hidden', name: name, value: $option.val()}));
      }
    });
    $form.appendTo('body').submit().remove();
  });
});
