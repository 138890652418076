function productComponent() {
    
    loadQtyInputs();

    $('.tab').on('click', function () {
        var tab = $(this).data('tab');
        $('.tab').removeClass('active');
        $('.tab-container').removeClass('active');

        $(this).addClass('active');
        $("#" + tab).addClass('active');
    });

    //Add to cart code
    $('.addtocart-form').on('submit', function (event) {
        event.preventDefault();
        const $form = $(this);
        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            success: function (data) {
                if (!data.success) {
                    if (data.code == 'MIXED_INTANGIBLE') {
                        showMessageBox('danger', msg['MSG_MIXED_INTANGIBLE']);
                    } else if (data.code == 'MIXED_CART') {
                        showMessageBox('danger', msg['MSG_MIXED_CART']);
                    } else if (data.code == 'LOGIN_REQUIRED') {
                        location.href = '/' + $('html').attr('lang') + '/login';
                    } else {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    }
                } else {
                    showMessageBox('success', msg['MSG_ADDEDTOCART']);
                    $form.find('.i-number').each(function () {
                        const value = $(this).val();
                        $(this).data('qtyorig', value);
                    });
                    $form.find('[type="submit"]').prop('disabled', true);
                    reloadCartPopup();
                    rewixUpdateCart($form);
                }
            },
            error: function (xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });
}

function preventNotNumericValues(e) {
    var charCode = (e.which) ? e.which : e.keyCode;

    // Allow: backspace, delete, tab, escape, enter and .
    if ($.inArray(charCode, [46, 8, 9, 27, 13, 110, 190]) !== -1 ||
        (charCode === 65 && (e.ctrlKey === true || e.metaKey === true)) ||
        (charCode >= 35 && charCode <= 40)) {
        // let it happen, don't do anything
        return;
    }
    // Ensure that it is a number and stop the keypress
    if ((e.shiftKey || (charCode < 48 || charCode > 57)) && (charCode < 96 || charCode > 105)) {
        e.preventDefault();
    }
}

function updateAddToCart(url, callback) {
    $('.addtocart-form-container').load(url + ' .addtocart-form', function () {
        if (typeof callback !== 'undefined') {
            callback();
        }
    });
}

function updateCartCount(url, callback) {
    $('#offCanvasCart').load(url + ' #offCanvasCartContainer', function () {
        if (typeof callback !== 'undefined') {
            callback();
        }
    });
}