function profilePage() {
    $('#copy-dispatch').on('click', function() {

        document.getElementById('addressee').value = document.getElementById('addressee_disp').value
        document.getElementById('cap').value = document.getElementById('cap_disp').value
        document.getElementById('city').value = document.getElementById('city_disp').value
        document.getElementById('number').value = document.getElementById('number_disp').value
        document.getElementById('prov').value = document.getElementById('prov_disp').value
        document.getElementById('street').value = document.getElementById('street_disp').value
        $('#country').val(document.getElementById('country_disp').value)

        checkRequiredFields();
    });

    function submitUserUpdateForm(form) {
        const $form = $(form);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function(data) {
                $form.find(':input').prop('disabled', true);
            },
            success: function(data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_SAVE_SUCCESS']);
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                $form.find(':input').prop('disabled', false);
            }
        });
    }

    function submitInvoiceForm(form) {
        const $form = $(form);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function(data) {
                $form.find(':input').prop('disabled', true);
            },
            success: function(data) {
                if (data.success) {
                    $form.find('[name="updateType"]').val('account');
                    $form.find(':input').prop('disabled', false);
                    const data = $form.serialize();
                    $.ajax({
                        type: $form.attr('method'),
                        url: $form.attr('action'),
                        data: data,
                        dataType: 'json',
                        beforeSend: function(data) {
                            $form.find(':input').prop('disabled', true);
                        },
                        success: function(data) {
                            if (data.success) {
                                showMessageBox('success', msg['MSG_SAVE_SUCCESS']);
                            } else {
                                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                            }
                        },
                        failed: function(data) {
                            showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                        },
                        complete: function(data) {
                            $form.find(':input').prop('disabled', false);
                            $form.find('[name="updateType"]').val('billing');
                        }
                    });
                } else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                $form.find(':input').prop('disabled', false);
            }
        });
    }

    $('#user-update-data-form').validate({
        errorPlacement: () => {},
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: submitUserUpdateForm
    });

    $('#user-update-dispatch-form').validate({
        errorPlacement: () => {},
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: submitUserUpdateForm
    });

    $('#user-update-invoice-form').validate({
        errorPlacement: () => {},
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: submitInvoiceForm
    });

    $('#password-change-form').on('submit', function(event) {
        event.preventDefault();
        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: $(this).serialize(),
            dataType: 'json',
            beforeSend: function(data) {
                $(this).find(':input').prop('disabled', true);
            },
            success: function(data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_PASSWORD_OK']);
                } else {
                    showMessageBox('danger', msg[data.errors.message]);
                }
            },
            complete: function(data) {
                $(this).find(':input').prop('disabled', false);
            }
        });
    });


    // profile consent ajax
    $('#profile-consent-form').on('submit', function(event) {
        event.preventDefault();
        var $form = $(this);

        var formData = {
            'userConsents':[]
        };

        $('.profile-privacy-input').each(function () {
            var obj = {};
            obj.consentId = $(this).attr("name");
            obj.value = $(this).is(":checked");
            formData.userConsents.push(obj);
        });

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: JSON.stringify(formData),
            dataType: 'json',
            contentType: 'application/json',
            beforeSend: function(data) {

            },
            success: function(data) {

            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                showMessageBox('success', msg['MSG_PROFILECONSENT_SAVED']);
            }
        });
    });

    $('#1to2step').click(function(event) {
        var form = $('#create-new-otp');
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            success: function(data) {
                $('#idnewotp').val(data.id);
                $('#qrcode').attr('src','data:image/png;base64, ' + data.data);
                $('#firstStep').hide();
                $('#secondStep').show();
                console.log(data);
            },
            failed: function(data) {
                console.log(data)
            }
        });
    });
    
    $('#enableOTP').click(function(event) {
        var form = $('#enable-new-otp');
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            success: function(data) {
                location.reload();
            },
            failed: function(data) {
                console.log(data)
            }
        });
    });
    
    $('#deleteOTP').click(function(event) {
        var form = $('#delete-otp');
        $.ajax({
            type: form.attr('method'),
            url: form.attr('action'),
            data: form.serialize(),
            dataType: 'json',
            success: function(data) {
                location.reload();
            },
            failed: function(data) {
                console.log(data)
            }
        });
	});

    $('#giftcard-form').on('submit', function(event) {
        event.preventDefault();

        const $form = $(this);
        $.ajax({
            method: $form.attr('method'),
            url: $form.attr('action'),
            dataType: 'json',
            data: $form.serialize(),
            beforeSend: function() {
                $form.find('[type="submit"]').prop('disabled', true);
            },
            success: function(data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_GIFTCARD_SUCCESS']);
                    $.ajax({
                        method: 'GET',
                        url: window.location.href,
                        success: function(data) {
                            let credit = $(data).find('#credit-span').html();
                            $('#credit-span').html(credit);
                        }
                    });
                }
                else {
                    showMessageBox('danger', msg[data.code] || msg['MSG_GENERIC_ERROR']);
                }
            },
            complete: function() {
                $form.find('[type="submit"]').prop('disabled', false);
            }
        });
    });

}