function showMessageBox(type, message, extra) {
    const boxType = 'alert-' + type;
    const content = '';
    if (extra) {
        content = `<div>${extra.content}</div>`;
    }
    const messageBox = `<div class="alert ${boxType} fade in">${message + content}</div>`;
    $('div.alert-box').html(messageBox);
    setTimeout(function() {
        $('div.alert-box').html('');
    }, 5000);
}

$(document).ready(function() {
    if ($('div.alert-box .alert').length > 0) {
        setTimeout(function() {
            $('div.alert-box').html('');
        }, 5000);
    }
});