function loginSubscribePage() {

    $('.password-eye').on('click', function () {
        const eye = $(this);
        const input = eye.siblings('input');
        const type = input.attr('type') == 'password' ? 'text' : 'password';

        eye.toggleClass('active');
        input.attr('type', type);
    });

    // show hide password recovery
    $('.js-toggle-show').on('click', function (event) {
        event.preventDefault();
        var $this = $(this);

        $($this.attr('data-show')).slideDown();
        $($this.attr('data-hide')).slideUp();
    });

    if (!$('.navigation').length) {
        $('.wrapper').css('margin-top', 0)
    }

    $('#password-recover-form').on('submit', function (event) {
        event.preventDefault();

        var $form = $(this);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function (data) {
                $form.find(':input').prop('disabled', true);
            },
            success: function (data) {
                if (data.success) {
                    const $tokenForm = $('#password-new-form-token');
                    $form.addClass('hide')
                    $tokenForm.removeClass('hide')
                    copyFormInputs('#password-recover-form', '#password-new-form-token', {
                        'email': 'email'
                    });
                    showMessageBox('success', msg['MSG_RECOVEREMAILSENT']);
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            failed: function (data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function (data) {
                $form.find(':input').prop('disabled', false);
                showMessageBox('success', msg['MSG_RECOVEREMAILSENT']);
            }
        });
    });

    $('#password-new-form-token').on('submit', function (event) {
        event.preventDefault();

        var $form = $(this);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function (data) {
                $form.find(':input').prop('disabled', true);
            },
            success: function (data) {
                if (data.success) {
                    showMessageBox('success', msg['MSG_RECOVEREMAILSENT']);
                    window.location.href = $form.data('nexturl');
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            failed: function (data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function (data) {
                $form.find(':input').prop('disabled', false);
                showMessageBox('success', msg['MSG_RECOVEREMAILSENT']);
            }
        });
    });

    function copyFormInputs(sourceForm, destForm, nameMap) {
        const source = $(sourceForm);
        const dest = $(destForm);

        for (const [sourceName, destName] of Object.entries(nameMap)) {
            const sourceInput = source.find(`[name=${sourceName}]`);
            const destInput = dest.find(`[name=${destName}]`);

            if (exists(sourceInput) && exists(destInput)) {
                destInput.val(sourceInput.val()).trigger('change');
            }
        }
    }

    $('#subscribe-form').validate({
        errorPlacement: () => { },
        invalidHandler: () => showMessageBox('danger', msg['ERR_MISSINGFIELDS']),
        submitHandler: function (form) {
            const $form = $(form);

            if ($('#password1').val() != $('#password2').val()) {
                showMessageBox('danger', msg['MSG_PASSWORD_NOT_EQUAL']);
            }
            else {
                $.ajax({
                    type: $form.attr('method'),
                    url: $form.attr('action'),
                    data: $form.serialize(),
                    dataType: 'json',
                    beforeSend: function (data) {
                        $form.find(':input').prop('disabled', true);
                    },
                    success: function (data) {
                        if (data.success) {
                            if (window.dataLayer) {
                                pushGTMEventWithCallback(500, {
                                    event: 'sign_up',
                                    eventCallback: GTMCallback(function() {
                                        window.location = $form.data('redirectsuccess');
                                    })
                                })
                            }
                            else {
                                window.location = $form.data('redirectsuccess');
                            }
                        }
                        else {
                            showMessageBox('danger', msg[data.errors.message]);
                        }
                    },
                    failed: function (data) {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                    },
                    complete: function (data) {
                        $form.find(':input').prop('disabled', false);
                    }
                });
            }
        }
    });

    $('#subscribeFormSubmit').on('click', function (event) {
        event.preventDefault();
        $('#subscribe-form').trigger('submit');
    });

    $('#consent4').on('change', function () {
        var i = 1;
        if ($(this).prop('checked')) {
            for (i = 5; i <= 6; i++) {
                $('#consent' + i).prop('checked', true);
            }
        }
        else {
            for (i = 5; i <= 6; i++) {
                $('#consent' + i).prop('checked', false);
            }
        }
    });

    eraseCookie('username');
    $('#login-form [type="submit"]').on('click', function (event) { //tutti gli el del form che hanno type submit
        event.preventDefault(); //sospende il refresh e l'attivazione del submit
        const consent = $('#rememberMe');
        if (!consent.is(':checked')) { // se "ricordami" non è checked
            const mail = $('#login-form [name="username"]').val();
            try {
                window.localStorage.setItem('username', mail); //crea un cookie dove chiamato username in cui viene salvato indirizzo mail digitato
            }
            catch {
                console.error('Could not save \'username\' to local storage');
            }
        }
        $('#login-form')[0].submit(); //riprende il submit
    });

    if (window.localStorage.getItem('username')) { //se esiste il cookie username
        $('#login-form [name="username"]').val(window.localStorage.getItem('username')); //l'indirizzo mail salvato sarà il valore del campo username
    }

}

