function cartPage() {

    loadQtyInputs();

    $('.cart-update').on('submit', function (event) {
        event.preventDefault();
        const $form = $(this);
        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            success: function (data) {
                rewixUpdateCart($form);
                window.location.href = '/current/cart';
            },
            error: function (xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });

    $('.btn-cart-remove').on('click', function (event) {
        event.preventDefault();
        const button = $(this);
        const name = button.attr('data-name');
        let data = {};
        data[name] = 0;

        $.ajax({
            type: 'POST',
            url: button.attr('data-action'),
            data: data,
            dataType: 'json',
            success: function (data) {
                const container = button.closest('.qty-input-container');
                rewixUpdateCart(container, true);
                window.location.href = '/current/cart';
            },
            error: function (xhr, textStatus, errorThrown) {
                showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
            }
        });
    });

    $(".burn-coupon-form").on('submit', function (event) {
        event.preventDefault();
        if ($(this).find("input[name=code]").val().length > 0) {
            $.ajax({
                type: "POST",
                url: $(this).attr('action'),
                data: $(this).serialize(),
                dataType: 'json',
                success: function (data) {
                    if (data.success) {
                        showMessageBox('success', msg['MSG_COUPON_VALID']);
                        window.location.href = "/current/cart";
                    } else {
                        showMessageBox('danger', msg['MSG_COUPON_ERROR']);
                    }
                }
            });
        } else {
            showMessageBox('danger', msg['MSG_COUPON_ERROR']);
        }
    });

    $('.carrier-form').on('change submit', function () {
        $.ajax({
            type: $(this).attr('method'),
            url: $(this).attr('action'),
            data: {
                country_id: $(this).find('select[name=country_id]').val(),
                carrier_id: $(this).find('input[name=carrier_id]:checked').val() != null ? $('input[name=carrier_id]:checked').val() : 0
            },
            dataType: 'json',
            success: function (data) {
                if (data.success) {
                    window.location.href = '/current/cart';
                }
                else if (data.code == 'INSUFFICIENT_STOCK') {
                    showMessageBox('danger', msg['MSG_INSUFFICIENT_STOCK']);
                }
                else {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                }
            },
            error: function () {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    });

    $('.cart-remove-product').on('click', function (event) {
        event.preventDefault();
        const btn = $(this);
        const currentTable = btn.siblings('.qty-table');
        const inputs = $(currentTable).find('.i-number');
        let data = {};

        inputs.each(function () {
            data[$(this).attr('name')] = 0;
        });

        $.ajax({
            type: 'POST',
            url: '/restful/shopping/public/checkout/addtocart',
            data: data,
            dataType: 'json',
            success: function (data) {
                if (data.success) {
                    const container = btn.closest('form');
                    rewixUpdateCart(container, true);
                    window.location.href = window.location.pathname;
                }
                else {
                    showMessageBox('danger', msg['DELETEALLPRODUCTSERROR']);
                }
            },
            error: function () {
                showMessageBox('danger', msg['DELETEALLPRODUCTSERROR']);
            }
        });

    });

    $('.cart-remove-all-products').on('click', function (event) {
        const button = $(this);
        if (confirm(msg['MSG_DELETEALLPRODUCTS'])) {
            const data = $('.cart-update [name*=qty_model]').toArray().reduce((a, b) => {
                const name = b.name;
                a[name] = 0;
                return a;
            }, {});

            $.ajax({
                type: 'POST',
                url: '/restful/shopping/public/checkout/addtocart',
                data: data,
                dataType: 'json',
                beforeSend: function () {
                    button.prop('disabled', true);
                },
                success: function (data) {
                    if (data.success) {
                        for (const form of $('.cart-update')) {
                            const $form = $(form);
                            rewixUpdateCart($form, true);
                        }
                        window.location.reload();
                    }
                    else {
                        showMessageBox('danger', msg['DELETEALLPRODUCTSERROR']);
                        button.prop('disabled', false);
                    }
                },
                error: function () {
                    showMessageBox('danger', msg['DELETEALLPRODUCTSERROR']);
                    button.prop('disabled', false);
                }
            });
        }
    });

    $('#remove-all-unavailable').on('click', function () {
        const data = $('#cart-container')
            .find('.quantity-container').toArray()
            .filter(input => $(input).data('max') < 1)
            .map(input => `${$(input).attr('name')}=0`)
            .join('&');

        if (data.length > 0) {
            $.ajax({
                type: 'POST',
                url: '/restful/shopping/public/checkout/addtocart',
                data: data,
                dataType: 'json',
                success: function () {
                    rewixUpdateCart($(this), true);
                    window.location.reload();
                },
                error: function () {
                    showMessageBox('danger', msg['DELETEALLPRODUCTSERROR']);
                }
            });
        }
        else {
            showMessageBox('danger', msg['DELETEALLPRODUCTSERROR']);
        }
    });
}