function subscriptionsPage() {

    if ($("[data-position=0]") != null) {

        var urlHashValue = parseInt(window.location.hash.substr(1));
        if (urlHashValue == '' || isNaN(urlHashValue)) {
            urlHashValue = $('.subscription-index[data-position=0]').attr('data-id');
        }
        $('#subscriptionOptionId').val(urlHashValue);
        if (urlHashValue != "") {

            $("[data-value=" + urlHashValue + "]").addClass('selected').show();
            var other = $('.subscription-index[data-id!=' + urlHashValue + ']').attr('data-id');
            $("[data-value-button=" + other + "]").show();

        }
        else {
            var first = $('.subscription-index[data-position=0]').attr('data-id');
            var second = $('.subscription-index[data-position!=0]').attr('data-id');
            $("[data-value=" + first + "]").addClass('selected').show();
            $("[data-value-button=" + second + "]").show();
        }
    }

    // prevent click on btn-table from opening order-controls
    $(".btn-table").click(function(e) {
        e.stopPropagation();
    });


    function initializePayment(orderId) {
        $.ajax({
            type: "POST",
            url: '/restful/shopping/checkout/payment/initialize',
            data: { orderId: orderId },
            dataType: 'json',
            success: function(data) {
                if (data.success) {
                    const selectedOption = $('.subscription-option.selected');
                    const matomoDataSignup = selectedOption.data('matomo-signup');
                    const matomoDataRecurring = selectedOption.data('matomo-recurring');
                    const dummyElement = $(`
                        <div>` +
                        (matomoDataSignup ? `
                            <input type="hidden" value="1" data-matomo='${JSON.stringify(matomoDataSignup)}'>
                        ` : '') + `
                            <input type="hidden" value="1" data-matomo='${JSON.stringify(matomoDataRecurring)}'>
                        </div>
                    `);
                    rewixUpdateCart(dummyElement);
                    window.location = '/current/checkout/billing';
                }
                else {
                    alert('Cannot pay this order.');
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            }
        });
    }


    // activate form ajax
    $('#subscription-activate-form').on('submit', function(event) {
        event.preventDefault();
        var $form = $(this);

        if(!$('#terms').is(':checked')) {
            showMessageBox('danger', msg['ERR_PRIVACY']);
        }
        else {
            $.ajax({
                type: $form.attr('method'),
                url: $form.attr('action'),
                data: $form.serialize(),
                dataType: 'json',
                beforeSend: function(data) {
                    $form.find(':input').prop('disabled', true);
                },
                success: function(data) {
                    if (data.success) {
                        initializePayment(data.data);
                    }
                    else {
                        showMessageBox('danger', msg['MSG_GENERIC_ERROR'] + ' ' + msg['MSG_SUBSCRIPTION_FAILURE']);
                    }
                },
                failed: function(data) {
                    showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
                },
                complete: function(data) {
                    $form.find(':input').prop('disabled', false);
                }
            });
        }
    });


    // profile reactivate form ajax
    $('.subscription-reactivate-form').on('submit', function(event) {
        event.preventDefault();
        var $form = $(this);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function(data) {

            },
            success: function(data) {
                if (data.success == false) {
                    showMessageBox('danger', data.errors.message);
                }
                else {
                    window.location.reload();
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
            }
        });
    });


    // profile unsubscribe form ajax
    $('.subscription-unsubscribe-form').on('submit', function(event) {
        event.preventDefault();
        var $form = $(this);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function(data) {

            },
            success: function(data) {
                if (data.success == false) {
                    showMessageBox('danger', data.errors.message);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                window.location.reload();
            }
        });
    });

    $('.subscription-change-form').on('submit', function(event) {
        event.preventDefault();
        var $form = $(this);

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function(data) {

            },
            success: function(data) {
                if (data.success == false) {
                    showMessageBox('danger', data.errors.message);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                window.location.reload();
            }
        });
    });

    // profile pay form ajax
    $('.pay-subscription').on('click', function(event) {
        var $this = $(this);
        initializePayment($this.attr('data-order-id'));
    });

    $('.change-subscription').click(function(event) {
        event.preventDefault();
        var $form = $('.subscription-change-form');

        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function(data) {

            },
            success: function(data) {
                if (data.success == false) {
                    showMessageBox('danger', data.errors.message);
                }
            },
            failed: function(data) {
                showMessageBox('danger', msg['MSG_GENERIC_ERROR']);
            },
            complete: function(data) {
                window.location.reload();
            }
        });
    });

}

function showSubscription(id) {
    var other = $('.subscription-index[data-id!=' + id + ']').attr('data-id');
    $('.subscription-element').removeClass('selected').hide();
    $("[data-value=" + id + "]").addClass('selected').fadeIn();
    $("[data-value-button=" + other + "]").fadeIn();
    $('#subscriptionOptionId').val(id);
}