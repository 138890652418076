function newsletterComponent() {

    $('#newsletter-arrow').on('click', function () {
        $('#newsletter-step-2').show();
    });

    $('#subscribe-newsletter').on('submit', function(event) {
        event.preventDefault();
        var $form = $(this);
        //$form.foundation("validateForm");
    });

    $('#subscribe-newsletter').bind("forminvalid.zf.abide", function(e,target) {
        showMessageBox('danger', msg['ERR_MISSINGFIELDS']);
    });

    $('#subscribe-newsletter').bind("formvalid.zf.abide", function(e,target) {
        var $form = $('#subscribe-newsletter');
        $.ajax({
            type: $form.attr('method'),
            url: $form.attr('action'),
            data: $form.serialize(),
            dataType: 'json',
            beforeSend: function(data) {

            },
            success: function(data) {
                if (data.success) {
                    $('#newsletter-step-2').hide();
                    $('#mail2').val('');
                    showMessageBox('success', msg['MSG_NEWSLETTER_COMPLETED'])
                }
                else {
                    showMessageBox('danger', msg['ERR_INVALIDMAIL']);
                }
            },
            error: function(data) {
            },
            complete: function(data) {
                grecaptcha.reset();
            }
        });
    });

}


function subscribeNewsletterCaptcha() {
    $('#subscribe-newsletter button[type=submit]').prop('disabled', false);
}